import {fork, select, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import errorTypes from '../../application/errorTypes';

const updatePricesByTimePeriod = function* updatePricesByTimePeriod({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    const {pricePeriodId, prices} = payload;

    yield fork(
        fetchRequest,
        priceManagementActionTypes.UPDATE_PRICES_BY_TIME_PERIOD_REQUEST,
        pricingManagementService.updatePricesByTimePeriod,
        {
            pricePeriodId,
            pricePeriodData: {prices},
        },
    );

    const responseAction = yield take([
        priceManagementActionTypes.UPDATE_PRICES_BY_TIME_PERIOD_REQUEST_SUCCEEDED,
        priceManagementActionTypes.UPDATE_PRICES_BY_TIME_PERIOD_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {pricePeriodDTO} = response;

        yield put({
            type: priceManagementActionTypes.STORE_PRICES_BY_TIME,
            payload: {pricePeriodDTO: {prices: pricePeriodDTO?.prices || []}},
        });
    }

    if (responseAction.error) {
        yield put({
            type: applicationActionTypes.INITIATE_ERROR_MESSAGE_FLOW,
            payload: {errorType: errorTypes.PRICE_MANAGEMENT_PRICES_BY_TIME_UPDATE_FAILED},
        });
    }
};

export default updatePricesByTimePeriod;
