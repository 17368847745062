import {put, select} from 'redux-saga/effects';
import {replace, resolveRoute, END_SIDE_EFFECTS_RUNNING} from '@computerrock/formation-router';
import routePaths from '../routePaths';

export const ensureRoute = function* ensureRoute() {
    const {location} = yield select(state => state.router);

    if (location.pathname === '/') {
        yield put(replace(resolveRoute(routePaths.DASHBOARD)));
        return END_SIDE_EFFECTS_RUNNING;
    }
};
