import {take, put, select, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import modalIds from '../../modalIds';
import fetchRequest from '../../application/sagas/fetchRequest';
import errorTypes from '../../application/errorTypes';

const createTimePeriodFlow = function* createTimePeriodFlow() {
    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');

    while (true) {
        yield take(priceManagementActionTypes.INITIATE_CREATE_TIME_PERIOD_FLOW);

        yield* openModal(modalIds.CREATE_TIME_PERIOD_MODAL);

        const chosenModalOption = yield take([
            priceManagementActionTypes.CONFIRM_CREATE_TIME_PERIOD,
            priceManagementActionTypes.DECLINE_CREATE_TIME_PERIOD,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === priceManagementActionTypes.CONFIRM_CREATE_TIME_PERIOD
        ) {
            const {searchQueryParams, file} = chosenModalOption.payload;

            yield fork(
                fetchRequest,
                priceManagementActionTypes.CREATE_PRICE_PERIOD_REQUEST,
                pricingManagementService.createPricePeriod,
                {
                    searchQueryParams,
                    file: file || '',
                },
            );

            const responseAction = yield take([
                priceManagementActionTypes.CREATE_PRICE_PERIOD_REQUEST_SUCCEEDED,
                priceManagementActionTypes.CREATE_PRICE_PERIOD_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {pricePeriodDTOs} = response;

                yield put({
                    type: priceManagementActionTypes.STORE_PRICES_BY_TIME,
                    payload: {pricePeriodDTO: pricePeriodDTOs},
                });
            }
            if (responseAction.error) {
                yield* closeModal(modalIds.CREATE_TIME_PERIOD_MODAL);
                yield put({
                    type: applicationActionTypes.INITIATE_ERROR_MESSAGE_FLOW,
                    payload: {errorType: errorTypes.PRICE_MATRIX_UPLOAD_FAILED},
                });
                continue;
            }
        }

        yield* closeModal(modalIds.CREATE_TIME_PERIOD_MODAL);
    }
};

export default createTimePeriodFlow;
