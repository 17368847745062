import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link, resolveRoute, useRouterState, useHistory} from '@computerrock/formation-router';
import {ContentItem} from '@ace-de/ui-components/app-layout';
import {Tab, TabBar} from '@ace-de/ui-components/navigation';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Option} from '@ace-de/ui-components';
import keycloakAuth from '../../keycloakAuth';
import AvatarMenu from './AvatarMenu';
import ACELogo from './ACELogo';
import {navigationBarAvatarMenuOptions} from './navigationBarAvatarMenuOptions';
import routePaths from '../../routePaths';
import dashboardScreenTabs from '../dashboardScreenTabs';

const NavigationBarContent = ({signOutUser, user}) => {
    const {cx} = useStyles();
    const {translate} = useTranslate();
    const {activeRoutePath} = useRouterState();
    const history = useHistory();

    const handleOnSelect = value => {
        if (value === navigationBarAvatarMenuOptions.LOGOUT) {
            signOutUser({});
            return;
        }
    };

    const navigateToRoute = routePath => {
        if (!routePath) return;

        history.push(resolveRoute(routePath));
    };

    return (
        <div className={
            cx([
                'ace-c-content-block',
                'global!ace-u-flex',
                'global!ace-u-flex--align-center',
            ])}
        >
            <ContentItem
                className={cx([
                    'ace-c-content-item--large-span-11',
                    'ace-c-content-item--x-large-span-11',
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <Link to={resolveRoute(routePaths.DASHBOARD)} className={cx('global!ace-u-typography--variant-body')}>
                    <ACELogo />
                </Link>
                <TabBar
                    name="dashboardTabs"
                    value={activeRoutePath}
                    onChange={navigateToRoute}
                >
                    <Tab
                        name={dashboardScreenTabs.CONTRACT_PARTNERS}
                        value={routePaths.CONTRACT_PARTNER_SEARCH}
                    >
                        {translate('navigation_bar.tab_name.contract_partners')}
                    </Tab>
                    <Tab
                        name={dashboardScreenTabs.QUALITY_MANAGEMENT}
                        value={routePaths.QUALITY_MANAGEMENT_FEEDBACKS_SEARCH}
                    >
                        {translate('navigation_bar.tab_name.quality_management')}
                    </Tab>
                    <Tab
                        name={dashboardScreenTabs.PRICE_MANAGEMENT}
                        value={routePaths.PRICE_MANAGEMENT}
                    >
                        {translate('navigation_bar.tab_name.price_management')}
                    </Tab>
                </TabBar>
            </ContentItem>
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-1',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-flex-end',
                    'global!ace-u-flex--align-center',
                ])}
            >
                {user && (
                    <AvatarMenu
                        userImage=""
                        userName={`${user.initials}`}
                        onSelect={handleOnSelect}
                    >
                        <Option
                            name="avatarOptionLogout"
                            value={navigationBarAvatarMenuOptions.LOGOUT}
                        >
                            {translate('navigation_bar.avatar_menu_option.log_out')}
                        </Option>
                    </AvatarMenu>
                )}
            </ContentItem>
        </div>
    );
};

NavigationBarContent.propTypes = {
    signOutUser: PropTypes.func,
    user: PropTypes.object,
};

NavigationBarContent.defaultProps = {
    signOutUser: () => null,
    user: null,
};

const mapStateToProps = state => ({
    user: state.user.user,
});

const mapDispatchToProps = dispatch => ({
    signOutUser: () => dispatch(keycloakAuth.signOutUserAction),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBarContent);
