import {put, take, select, fork} from 'redux-saga/effects';
import moment from 'moment';
import {apsPriceTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as priceManagementActionTypes from '../priceManagementActionTypes';

const loadActiveFixedPricePeriod = function* loadActiveFixedPricePeriod({payload}) {
    const {match} = payload;
    const {contractPartnerId} = match?.params;

    if (!contractPartnerId) return;

    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    const searchQueryParams = new URLSearchParams();

    searchQueryParams.append('contractPartner', contractPartnerId);
    searchQueryParams.append('validOn', moment().format('YYYY-MM-DD'));
    searchQueryParams.append('type', apsPriceTypes.CONTRACT_PARTNER_FIXED_PRICE);

    yield fork(
        fetchRequest,
        priceManagementActionTypes.FETCH_ACTIVE_FIXED_PRICE_FOR_CP_REQUEST,
        pricingManagementService.getValidPrice,
        {searchQueryParams},
    );

    const responseAction = yield take([
        priceManagementActionTypes.FETCH_ACTIVE_FIXED_PRICE_FOR_CP_REQUEST_SUCCEEDED,
        priceManagementActionTypes.FETCH_ACTIVE_FIXED_PRICE_FOR_CP_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {pricePeriodDTOs} = response;
        yield put({
            type: priceManagementActionTypes.SET_ACTIVE_PERIOD_FIXED_PRICE,
            payload: {contractPartnerId, pricePeriodDTO: pricePeriodDTOs[0]},
        });
    }
};

export default loadActiveFixedPricePeriod;
