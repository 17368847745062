import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {isValidIBAN, electronicFormatIBAN} from 'ibantools';
import {ButtonPrimary, useStyles} from '@ace-de/ui-components';
import {Form, InputField, Option, SelectField} from '@ace-de/ui-components/form';
import {Icon, saveIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import {alfAccountTypes, alfBusinessRelationTypes, alfContactTypes, alfCreditorTemplateCodeTypes, europeanCountries, alfClientTypes} from '@ace-de/eua-entity-types'; // eslint-disable-line max-len
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import bcModalTypes from '../modals/bcModalTypes';
import * as contractPartnersActionTypes from '../contractPartnerActionTypes';

const swiftCodeRegex = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
const vatNumberRegex = /^(DE)?[0-9]{9}$/;

const paymentTermsCodeOptions = {
    MONTHLY: 'MONTHLY',
    NEXT_CYCLE: 'NEXT_CYCLE',
};

const BCContractPartnerModalView = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateModal = createTranslateShorthand('bc_contract_partner_modal');
    const {location, submitCreditorCreation, submitContractPartnerCreation} = props;
    const {selectedContact, selectedAccountParty} = props;
    const {submitUpdateCreditor, submitUpdateContractPartner} = props;
    const searchQueryParams = new URLSearchParams(location?.search);
    const modalType = searchQueryParams.get('type');
    const clientType = searchQueryParams.get('client');
    const [formData, setFormData] = useState({
        creditorTemplateCode: clientType !== alfClientTypes.WACE
            ? alfCreditorTemplateCodeTypes.VENDOR_DOMESTIC_COMPANY
            : alfCreditorTemplateCodeTypes.VENDOR_DOMESTIC_COMPANY_WACE,
        businessRelations: [alfBusinessRelationTypes.CONTRACT_PARTNER],
        type: alfContactTypes.COMPANY,
        ...(!!selectedAccountParty && {
            bankAccount: selectedAccountParty.bankAccount,
            vatNumber: selectedAccountParty.vatNumber,
            paymentTermsCode: selectedAccountParty.paymentTermsCode,
        }),
    });
    const [isVATValid, setIsVATValid] = useState();
    const [countryErrorMessage, setCountryErrorMessage] = useState('');
    const [isBICValid, setIsBICValid] = useState(!selectedAccountParty?.bankAccount?.swiftCode
        || swiftCodeRegex.test(selectedAccountParty.bankAccount.swiftCode));
    const [isIBANValid, setIsIBANValid] = useState(isValidIBAN(electronicFormatIBAN(
        selectedAccountParty?.bankAccount?.iban?.toUpperCase(),
    )));
    const [isOnlyContact, setIsOnlyContact] = useState(modalType === bcModalTypes.UPDATE_CONTRACT_PARTNER);

    const validateVATNumber = useCallback(() => {
        if (!formData?.vatNumber) {
            setIsVATValid(false);
            return;
        }
        if (formData?.address?.country === europeanCountries['DE'].name && formData?.vatNumber) {
            setIsVATValid(vatNumberRegex.test(formData.vatNumber));
            return;
        }
        setIsVATValid(true);
    }, [formData?.vatNumber, formData?.address?.country]);

    useEffect(() => {
        validateVATNumber();
    }, [validateVATNumber]);

    useEffect(() => {
        if (!selectedContact) return;
        setFormData({
            ...selectedContact,
            creditorTemplateCode: clientType !== alfClientTypes.WACE
                ? alfCreditorTemplateCodeTypes.VENDOR_DOMESTIC_COMPANY
                : alfCreditorTemplateCodeTypes.VENDOR_DOMESTIC_COMPANY_WACE,
            ...((modalType === bcModalTypes.CREATE_CREDITOR) && {
                paymentTermsCode: paymentTermsCodeOptions.MONTHLY,
            }),
            businessRelations: [
                ...(selectedContact.businessRelations.find(relation => (
                    relation === alfBusinessRelationTypes.CONTRACT_PARTNER
                )) ? [...selectedContact.businessRelations]
                    : [...selectedContact.businessRelations, alfBusinessRelationTypes.CONTRACT_PARTNER]),
            ],
            ...(!!selectedAccountParty && {
                bankAccount: selectedAccountParty.bankAccount,
                vatNumber: selectedAccountParty.vatNumber,
                paymentTermsCode: selectedAccountParty.paymentTermsCode,
            }),
            type: selectedContact?.type || alfContactTypes.COMPANY,
        });
    }, [selectedContact, selectedAccountParty, modalType, clientType]);

    const validateCountry = useCallback(() => {
        if (formData?.address?.country && !Object.values(europeanCountries).find(europeanCountry => {
            return europeanCountry.name === formData.address.country;
        })) {
            setCountryErrorMessage(translateModal('error_message.select_option_from_dropdown'));
            return;
        }
        setCountryErrorMessage('');
    }, [formData?.address?.country, setCountryErrorMessage, translateModal]);

    useEffect(() => {
        validateCountry();
    }, [validateCountry]);

    const handleOnFormChange = formValues => {
        if (!formValues) return;
        const {street, city, country, postCode, swiftCode, bankName, iban, isOnlyContact, type, creditorTemplateCode, businessRelations, ...restFormValues} = formValues; // eslint-disable-line max-len

        setIsOnlyContact(isOnlyContact);

        setFormData({
            ...restFormValues,
            address: {
                ...(street && {street}),
                ...(city && {city}),
                ...(country && {country}),
                ...(postCode && {postCode}),
            },
            businessRelations: [
                ...(selectedContact?.businessRelations.find(relation => (
                    relation === alfBusinessRelationTypes.CONTRACT_PARTNER
                )) ? [...selectedContact.businessRelations]
                    : [
                        ...(selectedContact?.businessRelations?.length > 0 ? selectedContact?.businessRelations : []),
                        alfBusinessRelationTypes.CONTRACT_PARTNER,
                    ]),
            ],
            type: selectedContact?.type || alfContactTypes.COMPANY,
            ...(!isOnlyContact && {
                bankAccount: {
                    ...(iban && {iban}),
                    ...(swiftCode && {swiftCode}),
                    ...(bankName && {name: bankName}),
                },
                creditorTemplateCode: clientType !== alfClientTypes.WACE
                    ? alfCreditorTemplateCodeTypes.VENDOR_DOMESTIC_COMPANY
                    : alfCreditorTemplateCodeTypes.VENDOR_DOMESTIC_COMPANY_WACE,
            }),
        });
    };

    const handleOnSubmit = () => {
        if (modalType === bcModalTypes.CREATE_CONTRACT_PARTNER) {
            submitContractPartnerCreation({
                contractPartnerData: {
                    ...formData,
                    paymentTermsCode: formData.paymentTermsCode || undefined,
                },
                isOnlyContact,
            });
        }
        if (modalType === bcModalTypes.CREATE_CREDITOR) {
            submitCreditorCreation({
                creditorData: {
                    ...formData,
                    id: selectedContact.id,
                    accountPartyType: alfAccountTypes.CREDITOR,
                    paymentTermsCode: formData.paymentTermsCode || undefined,
                },
                shouldPatchContact: !(selectedContact.businessRelations.find(businessRelation => (
                    businessRelation === alfBusinessRelationTypes.CONTRACT_PARTNER
                ))),
            });
        }
        if (modalType === bcModalTypes.UPDATE_CONTRACT_PARTNER) {
            submitUpdateContractPartner({
                contactId: selectedContact.id,
                contractPartnerData: {
                    ...formData,
                },
                isOnlyContact,
            });
        }
        if (modalType === bcModalTypes.UPDATE_CREDITOR) {
            submitUpdateCreditor({
                contactId: selectedContact.id,
                contractPartnerData: {
                    ...formData,
                    id: selectedContact.id,
                    accountPartyType: alfAccountTypes.CREDITOR,
                },
            });
        }
    };

    const isSaveCTADisabled = !((formData?.name && formData?.address?.street
        && formData?.address?.postCode && formData?.address?.city
        && formData?.address?.country && !countryErrorMessage)
        && (!isOnlyContact
            ? ((formData?.bankAccount?.iban && isIBANValid)
            && formData?.creditorTemplateCode
            && (formData?.bankAccount?.swiftCode ? isBICValid : true)
            && isVATValid)
            : true));

    return (
        <div className={cx('global!ace-u-margin--0-32')}>
            <Form name="bcContractPartnerData" onChange={handleOnFormChange} onSubmit={handleOnSubmit}>
                <div
                    className={cx([
                        'global!ace-u-margin--24-0',
                        'global!ace-u-grid',
                    ])}
                >
                    <InputField
                        name="type"
                        label={translateModal('input_field_label.contact_type')}
                        className={cx('global!ace-u-grid-column--span-4')}
                        value={formData?.type
                            ? translateModal(`contact_type.${formData.type.toLowerCase()}`)
                            : ''}
                        isDisabled={true}
                        isFieldRequired={true}
                    />
                    <InputField
                        name="businessRelations"
                        label={translateModal('input_field_label.business_relation')}
                        className={cx('global!ace-u-grid-column--span-4')}
                        value={formData?.businessRelations?.length > 0
                            ? formData.businessRelations.map(businessRelation => (
                                translate(`global.business_relation_type.${businessRelation.toLowerCase()}`)
                            )).join(', ')
                            : ''}
                        isDisabled={true}
                        isFieldRequired={true}
                    />
                    <SelectField
                        name="isOnlyContact"
                        label={translateModal('select_field_label.contact_or_creditor')}
                        className={cx('global!ace-u-grid-column--span-4')}
                        value={isOnlyContact}
                        isDisabled={modalType === bcModalTypes.CREATE_CREDITOR
                            || modalType === bcModalTypes.UPDATE_CREDITOR
                            || modalType === bcModalTypes.UPDATE_CONTRACT_PARTNER}
                        isFieldRequired={true}
                    >
                        <Option
                            name="contactAndCreditor"
                            value={false}
                        >
                            {translateModal('option_label.contact_and_creditor')}
                        </Option>
                        <Option
                            name="contact"
                            value={true}
                        >
                            {translateModal('option_label.contact_only')}
                        </Option>
                    </SelectField>
                    <InputField
                        name="name"
                        label={translateModal('input_field_label.name')}
                        className={cx('global!ace-u-grid-column--span-4')}
                        value={formData?.name || ''}
                        isFieldRequired={true}
                    />
                    <InputField
                        name="street"
                        label={translateModal('input_field_label.address')}
                        className={cx('global!ace-u-grid-column--span-4')}
                        value={formData?.address?.street || ''}
                        isFieldRequired={true}
                    />
                </div>
                <div
                    className={cx([
                        'global!ace-u-margin--24-0',
                        'global!ace-u-grid',
                    ])}
                >
                    <InputField
                        name="postCode"
                        label={translateModal('input_field_label.post_code')}
                        className={cx('global!ace-u-grid-column--span-4')}
                        value={formData?.address?.postCode || ''}
                        isFieldRequired={true}
                    />
                    <InputField
                        name="city"
                        label={translateModal('input_field_label.city')}
                        className={cx('global!ace-u-grid-column--span-4')}
                        value={formData?.address?.city || ''}
                        isFieldRequired={true}
                    />
                    <InputField
                        name="country"
                        label={translateModal('select_field_label.country')}
                        className={cx('global!ace-u-grid-column--span-4')}
                        value={formData?.address?.country || europeanCountries.DE.name}
                        isFieldRequired={true}
                        isDisabled={true}
                    />
                    {!isOnlyContact && (
                        <>
                            <InputField
                                name="creditorTemplateCode"
                                label={translateModal('input_field_label.creditor_template_code')}
                                className={cx('global!ace-u-grid-column--span-4')}
                                value={formData?.creditorTemplateCode
                                    ? translateModal(`input_field_value.${formData?.creditorTemplateCode.toLowerCase()}`)
                                    : ''}
                                isDisabled={true}
                                isFieldRequired={true}
                            />
                            <SelectField
                                name="paymentTermsCode"
                                label={translateModal('select_field_label.payment_terms_code')}
                                className={cx('global!ace-u-grid-column--span-4')}
                                value={formData?.paymentTermsCode || ''}
                            >
                                {Object.keys(paymentTermsCodeOptions).map(key => (
                                    <Option
                                        key={key}
                                        name={`${key.toLowerCase()}PaymentTermsCode`}
                                        value={key}
                                    >
                                        {translateModal(`select_field_option.${paymentTermsCodeOptions[key].toLowerCase()}`)}
                                    </Option>
                                ))}
                            </SelectField>
                            <InputField
                                name="vatNumber"
                                label={`${translateModal('input_field_label.vat_number')}*`}
                                className={cx('global!ace-u-grid-column--span-4')}
                                value={formData?.vatNumber || ''}
                                errors={formData?.vatNumber && !isVATValid ? [translateModal('error_message.invalid_vat_no')] : []}
                            />
                            <InputField
                                name="iban"
                                label={translateModal('input_field_label.iban')}
                                className={cx('global!ace-u-grid-column--span-4')}
                                value={formData?.bankAccount?.iban || ''}
                                onBlur={() => {
                                    setIsIBANValid(isValidIBAN(electronicFormatIBAN(
                                        formData?.bankAccount?.iban?.toUpperCase(),
                                    )));
                                }}
                                errors={formData?.bankAccount?.iban
                                && typeof isIBANValid !== 'undefined'
                                && !isIBANValid
                                    ? [translateModal('error_message.invalid_iban')]
                                    : []}
                                isFieldRequired={true}
                            />
                            <InputField
                                name="swiftCode"
                                label={translateModal('input_field_label.swiftCode')}
                                className={cx('global!ace-u-grid-column--span-4')}
                                value={formData?.bankAccount?.swiftCode || ''}
                                onBlur={() => {
                                    formData?.bankAccount?.swiftCode
                                        ? setIsBICValid(swiftCodeRegex.test(
                                            formData.bankAccount.swiftCode,
                                        ))
                                        : setIsBICValid(true);
                                }}
                                errors={formData?.bankAccount?.swiftCode && !isBICValid ? [translateModal('error_message.invalid_bic')] : []}
                            />
                            <InputField
                                name="bankName"
                                label={translateModal('input_field_label.bank')}
                                className={cx('global!ace-u-grid-column--span-4')}
                                value={formData?.bankAccount?.name || ''}
                            />
                        </>
                    )}
                </div>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                        'global!ace-u-margin--24-0',
                    ])}
                >
                    <ButtonPrimary type="submit" isDisabled={isSaveCTADisabled}>
                        <Icon
                            icon={saveIcon}
                            className={cx([
                                'global!ace-c-icon--color-contrast',
                                'global!ace-u-margin--right-8',
                            ])}
                        />
                        {translateModal('button_label.save')}
                    </ButtonPrimary>
                </div>
            </Form>
        </div>
    );
};

BCContractPartnerModalView.propTypes = {
    selectedContact: PropTypes.object,
    selectedAccountParty: PropTypes.object,
    location: PropTypes.object.isRequired,
    submitCreditorCreation: PropTypes.func.isRequired,
    submitContractPartnerCreation: PropTypes.func.isRequired,
    submitUpdateCreditor: PropTypes.func.isRequired,
    submitUpdateContractPartner: PropTypes.func.isRequired,
};

BCContractPartnerModalView.defaultProps = {
    selectedContact: null,
    selectedAccountParty: null,
};

const mapStateToProps = (state, props) => {
    const getSelectedContact = contractPartnerSelectors.createContactSelector();
    const getSelectedAccountParty = contractPartnerSelectors.createAccountPartySelector();
    return {
        selectedContact: getSelectedContact(state, props),
        selectedAccountParty: getSelectedAccountParty(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitCreditorCreation: payload => dispatch({
        type: contractPartnersActionTypes.SUBMIT_CREDITOR_CREATION,
        payload,
    }),
    submitContractPartnerCreation: payload => dispatch({
        type: contractPartnersActionTypes.SUBMIT_CONTRACT_PARTNER_CREATION,
        payload,
    }),
    submitUpdateCreditor: payload => dispatch({
        type: contractPartnersActionTypes.SUBMIT_UPDATE_CREDITOR,
        payload,
    }),
    submitUpdateContractPartner: payload => dispatch({
        type: contractPartnersActionTypes.SUBMIT_UPDATE_CONTRACT_PARTNER,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BCContractPartnerModalView));
