/**
 * App config
 */
export default {
    // General
    APP_VERSION: process.env.APP_VERSION,
    APP_BUILD: process.env.APP_BUILD,
    DEPLOYMENT_ENV: process.env.DEPLOYMENT_ENV,
    PUBLIC_URL: process.env.PUBLIC_URL || '/',
    BACKDOOR_URL: process.env.BACKDOOR_URL,
    LOCALE: process.env.LOCALE || 'en',
    CURRENCY: process.env.CURRENCY || 'EUR',
    ACE_PARTNER_MANAGEMENT_APM_SERVICE_URL: process.env.ACE_PARTNER_MANAGEMENT_APM_SERVICE_URL,
    ACE_USER_PROFILE_EUP_SERVICE_URL: process.env.ACE_USER_PROFILE_EUP_SERVICE_URL,
    ACE_ECS_FLOW_EF_SERVICE_URL: process.env.ACE_ECS_FLOW_EF_SERVICE_URL,
    ACE_KEYCLOAK_SERVICE_URL: process.env.ACE_KEYCLOAK_SERVICE_URL,
    ACE_KEYCLOAK_REALM: process.env.ACE_KEYCLOAK_REALM,
    ACE_KEYCLOAK_CLIENT_ID: process.env.ACE_KEYCLOAK_CLIENT_ID,
    ACE_LOCALE_TRANSLATION_EN_US_URL: process.env.ACE_LOCALE_TRANSLATION_EN_US_URL,
    ACE_LOCALE_TRANSLATION_DE_DE_URL: process.env.ACE_LOCALE_TRANSLATION_DE_DE_URL,
    COOKIE_DOMAIN: process.env.COOKIE_DOMAIN || undefined,
    DATADOG_RUM_APPLICATION_ID: process.env.DATADOG_RUM_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN: process.env.DATADOG_CLIENT_TOKEN,
    DATADOG_SERVICE: process.env.DATADOG_SERVICE,
    DATADOG_ENABLE: process.env.DATADOG_ENABLE === 'true',
    ARCGIS_OAUTH2_SERVICE_URL: process.env.ARCGIS_OAUTH2_SERVICE_URL,
    ARCGIS_OAUTH2_CLIENT_ID: process.env.ARCGIS_OAUTH2_CLIENT_ID,
    ARCGIS_SERVICE_URL: process.env.ARCGIS_SERVICE_URL,
    TEMP_ARCGIS_MAP_ID: process.env.TEMP_ARCGIS_MAP_ID || '',
    ARCGIS_CONTRACT_PARTNER_LOCATIONS_LAYER_URL: process.env.ARCGIS_CONTRACT_PARTNER_LOCATIONS_LAYER_URL || '',
    ARCGIS_CONTRACT_PARTNER_SERVICE_AREAS_LAYER_URL: process.env.ARCGIS_CONTRACT_PARTNER_SERVICE_AREAS_LAYER_URL || '',
    ACE_APM_FILE_ASSETS_SERVICE_ENDPOINT: process.env.ACE_APM_FILE_ASSETS_SERVICE_ENDPOINT || '',
    ACE_APM_QUALITY_MANAGEMENT_FEEDBACKS_EXPORT_ENDPOINT: process.env.ACE_APM_QUALITY_MANAGEMENT_FEEDBACKS_EXPORT_ENDPOINT || '',
    ACE_APM_CONTRACT_PARTNERS_OVERVIEW_EXPORT_ENDPOINT: process.env.ACE_APM_CONTRACT_PARTNERS_OVERVIEW_EXPORT_ENDPOINT || '',
    ACE_LEA_ASSIGNMENT_FLOW_ALF_SERVICE_URL: process.env.ACE_LEA_ASSIGNMENT_FLOW_ALF_SERVICE_URL || '',
    DRIVER_ELLA_USER_MANAGEMENT_DEUM_SERVICE_ENDPOINT: process.env.DRIVER_ELLA_USER_MANAGEMENT_DEUM_SERVICE_ENDPOINT || '',
    ACE_PRICING_MANAGEMENT_APS_SERVICE_URL: process.env.ACE_PRICING_MANAGEMENT_APS_SERVICE_URL || '',
    ACE_ECS_HISTORY_MANAGEMENT_EHM_SERVICE_URL: process.env.ACE_ECS_HISTORY_MANAGEMENT_EHM_SERVICE_URL || '',
    STUTTGART_CENTER_COORDINATES: [9.1829, 48.7758],
    GERMANY_CENTER_COORDINATES: [10.451500, 51.165700],
    MINIMUM_MAP_ZOOM_LEVEL_FOR_SEARCHING: 9,
    DEFAULT_ARCGIS_MAP_WIDGET_ZOOM_LEVEL: 10,
    DEFAULT_PAGE_SIZE: 20,
    CONTRACT_PARTNERS_SEARCH_COUNTRY_CODE: 'DE',
    MAXIMUM_TEXT_AREA_CONTENT_LENGTH: 250,
    TEMPORARY_RESTRICTIONS_DEFAULT_PAGE_SIZE: 10,
    MINIMUM_SEARCH_QUERY_LENGTH: 3,
    RELATIONS_NUMBER_OF_ROWS: 6,
    MAXIMUM_INPUT_CONTENT_LENGTH: 50,
    DEFAULT_TIME_PERIOD_IN_MONTHS: 6,
    ACE_ECS_SITE_URL: process.env.ACE_ECS_SITE_URL,
    ACE_ECS_SITE_BACKDOOR_URL: process.env.ACE_ECS_SITE_BACKDOOR_URL,
    ECS_SERVICE_CASE_SCREEN_PATH: '/service-cases/:serviceCaseId',
    ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER: 450,
    ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT: 10,
    MAXIMUM_QMN_DESCRIPTION_TEXT_AREA_CONTENT_LENGTH: 1000,
    MAXIMUM_FILE_ASSETS_FILE_SIZE_BYTES: 52428800,
    MAXIMUM_FILE_ASSETS_IN_VIEW: 5,
    MIN_CONTRACT_PARTNER_FIXED_PRICE: 0,
    MAX_CONTRACT_PARTNER_FIXED_PRICE: 999,
    MIN_ULTRA_HEAVY_WEIGHT_VEHICLE_TONNAGE: 7.51,
    POST_CODE_FROM: '00000',
    POST_CODE_TO: '99999',
    GERMAN_NEIGHBORING_COUNTRIES: ['DK', 'PL', 'CZ', 'AT', 'CH', 'FR', 'LU', 'BE', 'NL'],
    STATE_CODE_CHAR_NUMBER: 2,
    MAXIMUM_FUTURE_TIME_PERIODS: 3,
    MINIMUM_CP_SEARCH_QUERY_LENGTH: 3,
    ADDITIONAL_RATES_BY_TIME_UNIT: 5,
    FIXED_PRICE_LIMIT: 5,
    PROTOCOL_DEFAULT_PAGE_SIZE: 500,
    PERCENTAGE: '%',
    MINIMUM_DISTANCE_VALUE: 100,
    MAXIMUM_DISTANCE_VALUE: 1000,
    NOT_ALLOWED_PRICE_VALUE: 0,
};
