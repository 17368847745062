import {take, put, select, fork, delay} from 'redux-saga/effects';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import routePaths from '../../routePaths';

const loadStateHolidayDates = function* loadStateHolidayDates({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const {holidays, hasHolidayUpdateFailed} = yield select(state => state.priceManagement);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    const {location} = payload;
    const {pathname} = location;

    if (Object.keys(holidays).length > 0 || pathname !== routePaths.PRICE_MANAGEMENT) {
        return;
    }

    yield put({
        type: priceManagementActionTypes.SET_HOLIDAYS_LOADING,
    });

    yield fork(
        fetchRequest,
        priceManagementActionTypes.FETCH_STATE_HOLIDAY_DATES_REQUEST,
        pricingManagementService.getStateHolidayDates,
    );

    const responseAction = yield take([
        priceManagementActionTypes.FETCH_STATE_HOLIDAY_DATES_REQUEST_SUCCEEDED,
        priceManagementActionTypes.FETCH_STATE_HOLIDAY_DATES_REQUEST_FAILED,
    ]);

    if (responseAction.error) {
        yield put({
            type: priceManagementActionTypes.RESET_HOLIDAYS_LOADING,
        });
        yield put({
            type: priceManagementActionTypes.SET_HOLIDAYS_ERROR,
            payload: null,
        });
    }

    if (!responseAction.error) {
        if (hasHolidayUpdateFailed) {
            yield put({
                type: priceManagementActionTypes.RESET_HOLIDAYS_ERROR,
                payload: null,
            });
        }
        const {response} = responseAction.payload;
        const {stateHolidayDatesDTOs} = response;

        yield put({
            type: priceManagementActionTypes.STORE_STATE_HOLIDAY_DATES,
            payload: {stateHolidayDatesDTOs},
        });
        yield delay(300);
        yield put({
            type: priceManagementActionTypes.RESET_HOLIDAYS_LOADING,
        });
    }
};

export default loadStateHolidayDates;
