import {take, put, select, fork} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import config from '../../config';

const loadContractPartnerFixedPricePeriods = function* loadContractPartnerFixedPricePeriods({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    const {match} = payload;
    const {contractPartnerId} = match?.params;

    if (!contractPartnerId) return;

    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append('contractPartner', contractPartnerId);
    searchQueryParams.append('limit', config.FIXED_PRICE_LIMIT);

    yield fork(
        fetchRequest,
        priceManagementActionTypes.GET_CP_FIXED_PRICES_REQUEST,
        pricingManagementService.getPricePeriods,
        {searchQueryParams},
    );

    const responseAction = yield take([
        priceManagementActionTypes.GET_CP_FIXED_PRICES_REQUEST_SUCCEEDED,
        priceManagementActionTypes.GET_CP_FIXED_PRICES_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {pricePeriodDTOs} = response;
        const bulkPeriodIds = pricePeriodDTOs.map(pricePeriodDTO => pricePeriodDTO.id);

        yield fork(
            fetchRequest,
            priceManagementActionTypes.GET_BULK_PRICE_PERIODS_REQUEST,
            pricingManagementService.getBulkPricePeriod,
            (bulkPeriodIds),
        );

        const bulkFetchResponseAction = yield take([
            priceManagementActionTypes.GET_BULK_PRICE_PERIODS_REQUEST_SUCCEEDED,
            priceManagementActionTypes.GET_BULK_PRICE_PERIODS_REQUEST_FAILED,

        ]);

        if (!bulkFetchResponseAction.error) {
            const {response} = bulkFetchResponseAction.payload;
            const {pricePeriodDTOs} = response;
            yield put({
                type: priceManagementActionTypes.STORE_FIXED_PRICES,
                payload: {
                    contractPartnerId,
                    pricePeriodDTOs: pricePeriodDTOs.map(pricePeriodDTO => pricePeriodDTO.pricePeriodDTO),
                },
            });
            return;
        }

        yield put({
            type: priceManagementActionTypes.STORE_FIXED_PRICES,
            payload: {
                contractPartnerId,
                pricePeriodDTOs,
            },
        });
    }
};

export default loadContractPartnerFixedPricePeriods;
